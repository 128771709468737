// src/pages/404.js
import React from "react";
// import { Link } from "gatsby";
import Layout from "../../node_modules/@lekoarts/gatsby-theme-minimal-blog/src/components/layout"

export default function NotFound() {
  return (
    <Layout>
      <h1>404 not found</h1>
    </Layout>
  )
}